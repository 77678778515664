body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GothamThin";
}

@font-face {
  font-family: "GothamBook";
  src: local("GothamBook"),
    url("./assets/fonts/GothamBook.ttf") format("truetype");
}
@font-face {
  font-family: "GothamThin";
  src: local("GothamThin"),
    url("./assets/fonts/Gotham-Thin.otf") format("opentype");
}
@font-face {
  font-family: "GothamLight";
  src: local("GothamLight"),
    url("./assets/fonts/Gotham-Light.otf") format("opentype");
}
